import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TranslationService } from './translation.service';

export const MOCK_TRANSLATIONS = new InjectionToken<{ [key: string]: string }>('MockTranslations');

@Injectable()
export class MockTranslationService implements TranslationService {
  constructor(@Inject(MOCK_TRANSLATIONS) @Optional() private translations?: { [key: string]: string }) {}

  translate(key: string | string[], options: any = {}, _whitespaceUntilLoaded: boolean = false): Observable<string> {
    return new Observable<string>((subscriber) => {
      let translation;
      if (typeof key === 'string') {
        translation = this.translations?.[key] ?? key;
      } else {
        translation = this.translations?.[key[0]] ?? key[0];
      }
      const value = translation
        .split('{{')
        .map((chunk) => {
          const [a, b] = chunk.split('}}');
          if (b) {
            return [options[a.trim()], b].join('');
          } else {
            return [a, b].join('');
          }
        })
        .join('');
      subscriber.next(value);
      subscriber.complete();
    });
  }

  translate2(key: string, _options: any = {}, _whitespaceUntilLoaded: boolean = false) {
    return of(key);
  }

  loadChunks(_chunks: string | string[]): Promise<any> {
    return Promise.resolve();
  }

  setDebug() {
    return false;
  }
}
